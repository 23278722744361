import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import useModal from '@src/hooks/useModal';
import useSettings from '@src/hooks/useSettings';
import SubscriptionWallModal from '@src/components/shared/modals/SubscriptionWallModal';

interface SubscriptionWallProps {
  children: JSX.Element;
}

function SubscriptionWall({ children }: SubscriptionWallProps) {
  const { business } = useSettings();
  const { open: openBlocker, isOpen } = useModal(SubscriptionWallModal);
  const router = useRouter();

  useEffect(() => {
    const isPaymentRoute =
      router.asPath ===
      `/invoice-payments?invoiceIds=${business?.subscriptionInvoice?.id}`;

    if (business?.activeSubscription === false && !isPaymentRoute && !isOpen) {
      openBlocker({
        invoiceId: business?.subscriptionInvoice?.id,
      });
    }
  }, [
    business?.activeSubscription,
    business?.subscriptionInvoice,
    openBlocker,
    router.asPath,
    isOpen,
  ]);

  return children;
}

export default SubscriptionWall;
