import React, { forwardRef } from 'react';

import {
  GhostedButton,
  IconWrapper,
  OutlinedButton,
  SolidButton,
  TransparentButton,
} from './Button.styles';

import Spinner from '../Spinner';
import { ButtonProps } from './types';

function Button(props: ButtonProps, ref: any) {
  const {
    children,
    startIcon,
    variant,
    endIcon,
    type,
    loading,
    theme,
    ...otherProps
  } = props;

  let StyledButton = SolidButton;
  switch (variant) {
    case 'solid':
      StyledButton = SolidButton;
      break;
    case 'ghosted':
      StyledButton = GhostedButton;
      break;
    case 'transparent':
      StyledButton = TransparentButton;
      break;
    case 'outlined':
      StyledButton = OutlinedButton;
      break;
  }

  return (
    <StyledButton
      ref={ref}
      type={type || 'button'}
      customLoading={loading}
      role="button"
      theme={theme}
      {...otherProps}
    >
      <div className="button-body">
        {startIcon && <IconWrapper>{startIcon}</IconWrapper>}
        <div className="button-label">{children}</div>
        {endIcon && <IconWrapper>{endIcon}</IconWrapper>}
        {loading && <Spinner />}
      </div>
    </StyledButton>
  );
}

export default forwardRef(Button);
