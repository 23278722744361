import { graphql } from 'msw';

export const termLoanInstallmentsHandler = graphql.query(
  'TermLoanInstallments',
  (req, res, ctx) => {
    const { repaid } = req.variables;

    if (repaid) {
      return res(
        ctx.data({
          termLoanInstallments: {
            edges: [
              {
                node: {
                  id: 1,
                  number: 1,
                  amountCents: 50550,
                  interestAmountCents: 3350,
                  dueDate: '2022-05-26T18:00:16-05:00',
                  repaidAt: '2022-05-26T18:00:16-05:00',
                  createdAt: '2022-05-11T18:00:16-05:00',
                },
              },
            ],
            pageInfo: {
              startCursor: 'mw',
              endCursor: 'MQ',
              hasNextPage: false,
              hasPreviousPage: false,
            },
            totalCount: 1,
          },
        })
      );
    }

    return res(
      ctx.data({
        termLoanInstallments: {
          edges: [
            {
              node: {
                id: 2,
                number: 2,
                amountCents: 50550,
                interestAmountCents: 3350,
                dueDate: '2022-06-10T18:00:16-05:00',
                repaidAt: undefined,
                createdAt: '2022-05-11T18:00:16-05:00',
              },
            },
            {
              node: {
                id: 3,
                number: 3,
                amountCents: 50550,
                interestAmountCents: 3350,
                dueDate: '2022-06-25T18:00:16-05:00',
                repaidAt: undefined,
                createdAt: '2022-05-11T18:00:16-05:00',
              },
            },
            {
              node: {
                id: 4,
                number: 4,
                amountCents: 50550,
                interestAmountCents: 3350,
                dueDate: '2022-07-10T18:00:16-05:00',
                repaidAt: undefined,
                createdAt: '2022-05-11T18:00:16-05:00',
              },
            },
          ],
          pageInfo: {
            startCursor: 'mw',
            endCursor: 'MQ',
            hasNextPage: false,
            hasPreviousPage: false,
          },
          totalCount: 1,
        },
      })
    );
  }
);
