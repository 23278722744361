import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './SubscriptionWallModal.styles';

import { Button, Header, Text } from '@ui';
import { Headphones } from '@ui/Icon/outline';
import useCustomerSupportChat from '@src/hooks/useCustomerSupportChat';
import useSettings from '@src/hooks/useSettings';
import TaxpayerDropdown from '@src/components/navigation/Navbar/components/TaxpayerDropdown';
import LinkButton from '@shared/buttons/LinkButton';
import { Spacer } from '@shared/layouts';

interface SubscriptionWallModalProps {
  invoiceId?: string;
  onClose?: () => void;
}

function SubscriptionWallModal({ invoiceId }: SubscriptionWallModalProps) {
  const intl = useIntl();
  const { currentUser } = useSettings();
  const openCustomerSupportChat = useCustomerSupportChat();

  return (
    <S.Container>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Cuenta suspendida',
          id: 'm026zr',
        })}
      </Header>
      <Text>
        {intl.formatMessage({
          defaultMessage:
            'Tu cuenta ha sido bloqueada por falta de pago, una vez realices el pago pendiente podrás acceder de nuevo',
          id: 'Vgqwzz',
        })}
      </Text>

      <Spacer />

      {invoiceId ? (
        <LinkButton block href={`/invoice-payments?invoiceIds=${invoiceId}`}>
          {intl.formatMessage({
            defaultMessage: 'Pagar suscripción',
            id: 'TlzBhI',
          })}
        </LinkButton>
      ) : (
        <Button
          block
          startIcon={<Headphones />}
          onClick={openCustomerSupportChat}
        >
          {intl.formatMessage({
            defaultMessage: 'Contactar a soporte',
            id: 'tTYher',
          })}
        </Button>
      )}

      {(currentUser?.businesses?.length ?? 0) > 1 && (
        <>
          {' '}
          <Spacer />
          <Text colorStep="600">
            {intl.formatMessage({
              defaultMessage: 'Cambiar de cuenta',
              id: 'aQbSDQ',
            })}
          </Text>
          <TaxpayerDropdown fullWidth />
        </>
      )}
    </S.Container>
  );
}

SubscriptionWallModal.displayName = 'SubscriptionWallModal';

export default SubscriptionWallModal;
