import styled, { css } from 'styled-components';

import { TabProps } from './types';

interface StyledTabProps extends TabProps {
  topLeftRadius?: boolean;
  topRightRadius?: boolean;
}

const hoverState = css`
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  color: ${({ theme }) => theme.colors.neutral[600]};

  .tag-content {
    p {
      font-weight: normal;
    }
  }

  &:after {
    content: none;
  }
`;

export const StyledTab = styled.button<StyledTabProps>`
  background-color: transparent;
  color: ${({ selected, theme }) =>
    selected ? theme.colors.primary[500] : theme.colors.neutral[600]};
  height: ${({ theme }) => theme.spacing[16]};
  width: ${({ theme }) => theme.spacing[52]};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  border-top-left-radius: ${({ topLeftRadius, theme }) =>
    topLeftRadius ? theme.spacing[4] : '0'};
  border-top-right-radius: ${({ topRightRadius, theme }) =>
    topRightRadius ? theme.spacing[4] : '0'};

  .tag-content {
    margin-left: ${({ theme }) => theme.spacing[8]};
    display: flex;
    align-items: center;
    p {
      margin: 0;
      font-weight: ${({ selected, theme }) =>
        selected ? theme.fontWeight.bold : theme.fontWeight.regular};
    }

    svg,
    i {
      height: ${({ theme }) => theme.spacing[4]};
      width: ${({ theme }) => theme.spacing[4]};
      margin-right: ${({ theme }) => theme.spacing[2]};
    }

    > div {
      margin-left: ${({ theme }) => theme.spacing[2]};
    }
  }

  &:after {
    content: '';
    visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3px;
    border-radius: 1.5px;
    background-color: ${({ theme }) => theme.colors.primary[500]};
  }

  &:hover {
    ${({ selected }) => !selected && hoverState};
  }
`;
