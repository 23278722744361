import React from 'react';
import { createGlobalStyle } from 'styled-components';

import FontStyles from './fontStyles';
import RebootStyles from './rebootStyles';

const DefaultStyles = createGlobalStyle`
   ::-moz-selection { 
      background: ${({ theme }) => theme.colors.primary[100]};
   }
   ::selection { 
      background: ${({ theme }) => theme.colors.primary[100]};
  }

   html {
    background-color: ${({ theme }) => theme.colors.neutral[100]};
    scroll-behavior: smooth;
    
    @media (max-width: 768px) {
      font-size: 14px;
    }
   }

   body {
    width: 100%;
    min-height: 100vh;
    color: ${({ theme }) => theme.colors.neutral[900]};
    background-color: ${({ theme }) => theme.colors.neutral[100]};
   }

   
    
    p, span, a {
      font-size: ${({ theme }) => theme.fontSize.sm};
      font-weight: ${({ theme }) => theme.fontWeight.regular};
    }
    
    small {
      font-size: ${({ theme }) => theme.fontSize['2xs']};
    }

    strong {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
    
   a {
     text-decoration: none;
     color: ${({ theme }) => theme.colors.neutral[900]};
     &:hover {
       text-decoration: none;
       color: ${({ theme }) => theme.colors.neutral[900]};
     }
   }
`;

function GlobalStyles() {
  return (
    <>
      <FontStyles />
      <RebootStyles />
      <DefaultStyles />
    </>
  );
}

export default GlobalStyles;
