import { CustomWindow } from '../../custom.window';

declare const window: CustomWindow;

const useCustomerSupportChat = () => () => {
  if (typeof window !== 'undefined' && window.zE) {
    window.zE('messenger', 'open');
  }
};

export default useCustomerSupportChat;
