import React from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';

import * as S from './CreateBusinessForm.styles';

import { Button, Header, TextField, Tooltip } from '@ui';
import useSettings from '@src/hooks/useSettings';
import useToast from '@src/hooks/useToast';
import {
  CREATE_BUSINESS,
  CreateBusinessReturn,
  CreateBusinessVariables,
} from '@src/graphql/mutations/createBusiness';
import { trackEvent } from '@src/lib/analytics';

interface CreateBusinessModalProps {
  onClose?: () => void;
}

type FormData = {
  businessName: string;
  taxpayerIdentifier: string;
  satPassword: string;
};

function CreateBusinessModal({ onClose }: CreateBusinessModalProps) {
  const intl = useIntl();
  const { currentUser, setCurrentBusinessId, refetch } = useSettings();
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormData>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });
  const [createBusiness, { loading }] = useMutation<
    CreateBusinessReturn,
    CreateBusinessVariables
  >(CREATE_BUSINESS, {
    onCompleted: async (data) => {
      const businessId = data?.createBusiness?.business?.id;
      if (businessId) {
        setCurrentBusinessId(businessId);
        await refetch();
        trackEvent('Business Created', {
          businessId: businessId,
          userId: currentUser?.id,
          userEmail: currentUser?.email,
          rfc: getValues('taxpayerIdentifier'),
        });
      }
      onClose && onClose();
    },
    onError: () => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al crear cuenta',
          id: 'zDYQ2G',
        })
      );
    },
  });

  const onSubmit = ({
    businessName,
    taxpayerIdentifier,
    satPassword,
  }: FormData) => {
    createBusiness({
      variables: {
        business: {
          name: businessName,
        },
        taxPayerInfo: {
          taxpayerIdentifier: taxpayerIdentifier.toUpperCase(),
          satPassword,
        },
      },
    });
  };

  return (
    <S.FormContainer>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Nueva razón social',
          id: 'OUTwCt',
        })}
      </Header>
      <p>
        {intl.formatMessage({
          defaultMessage:
            'Completa la información de tu nueva razón social para continuar.',
          id: 'I5IS8m',
        })}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register('taxpayerIdentifier', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
            maxLength: {
              value: 13,
              message: intl.formatMessage({
                defaultMessage:
                  'Debe contener hasta 13 caracteres alfanuméricos.',
                id: 'g7O2+a',
              }),
            },
            minLength: {
              value: 12,
              message: intl.formatMessage({
                defaultMessage:
                  'Debe contener mínimo 12 caracteres alfanuméricos.',
                id: '2vKU/U',
              }),
            },
            pattern: {
              value: /[A-Z]{3,4}[0-9]{6}[A-Z0-9]{3}/,
              message: intl.formatMessage({
                defaultMessage: 'No cumple con el formato correcto',
                id: '1yqXVx',
              }),
            },
            setValueAs: (value) => value.toUpperCase(),
          })}
          type="text"
          label={intl.formatMessage({
            defaultMessage: 'RFC',
            id: 'wvCzn7',
          })}
          placeholder={intl.formatMessage({
            defaultMessage: 'Ej. ABCDEF123456',
            id: 'PJ1cUH',
          })}
          required
          fullWidth
          autoFocus
          helperText={errors?.taxpayerIdentifier?.message}
          error={!!errors?.taxpayerIdentifier?.message}
          data-testid="taxpayer-identifier-input"
        />

        <TextField
          {...register('businessName', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
          })}
          label={intl.formatMessage({
            defaultMessage: 'Razón social',
            id: 'pmiu07',
          })}
          placeholder={intl.formatMessage({
            defaultMessage: 'Ej. Acme Inc.',
            id: 'N3TkoY',
          })}
          required
          fullWidth
          helperText={errors?.businessName?.message}
          error={!!errors?.businessName?.message}
          data-testid="business-name-input"
        />

        <TextField
          {...register('satPassword', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
          })}
          label={intl.formatMessage({
            defaultMessage: 'Contraseña CIEC',
            id: 'bNmcp4',
          })}
          required
          type="password"
          fullWidth
          helperText={
            errors?.satPassword?.message || (
              <Tooltip
                id="signup_tooltip"
                place="top"
                width={400}
                title={intl.formatMessage({
                  defaultMessage:
                    'Tu contraseña CIEC la utilizamos únicamente para verificar que la información de tus facturas y proveedores es real y poder determinar tu capacidad de endeudamiento.',
                  id: 'H2TpmL',
                })}
              >
                <S.TooltipSpan>
                  {intl.formatMessage({
                    defaultMessage: '¿Para que te pedimos la contraseña CIEC?',
                    id: 'OtcRJr',
                  })}
                </S.TooltipSpan>
              </Tooltip>
            )
          }
          error={!!errors?.satPassword?.message}
          placeholder={'************'}
          data-testid="password-input"
        />

        <div className="buttons-container">
          <Button variant="ghosted" onClick={onClose} data-testid="cancel">
            {intl.formatMessage({
              defaultMessage: 'Cancelar',
              id: 'nZLeaQ',
            })}
          </Button>
          <Button
            type="submit"
            loading={loading}
            data-testid="create-business-submit"
          >
            {intl.formatMessage({
              defaultMessage: 'Continuar',
              id: '1JuMPJ',
            })}
          </Button>
        </div>
      </form>
    </S.FormContainer>
  );
}

CreateBusinessModal.displayName = 'CreateBusinessModal';

export default CreateBusinessModal;
