import { gql } from '@apollo/client';

import { ClientRelationship } from '@src/types/models';

const CLIENT_RELATIONSHIPS_ATTRIBUTES = gql`
  fragment ClientRelationshipsAttributes on BusinessRelationship {
    id
    status
    lastOrderDate
    clientId
    clientName
    clientContact {
      id
      email
    }
    last30DaysOrdersTotals {
      value
      currency
    }
    deliveryOnSaturday
    deliveryOnSunday
    shippingCostType
    shippingCostValue
    shippingCostCurrency
    deliveryLeadTimeInDays
    paymentTerms
    priceList {
      id
      name
      createdBy
    }
  }
`;

export type ClientRelationshipAttributes = Pick<
  ClientRelationship,
  | 'id'
  | 'status'
  | 'lastOrderDate'
  | 'clientId'
  | 'clientName'
  | 'clientContact'
  | 'last30DaysOrdersTotals'
  | 'deliveryOnSaturday'
  | 'deliveryOnSunday'
  | 'shippingCostType'
  | 'shippingCostValue'
  | 'shippingCostCurrency'
  | 'deliveryLeadTimeInDays'
  | 'paymentTerms'
  | 'priceList'
>;

export default CLIENT_RELATIONSHIPS_ATTRIBUTES;
