import { graphql } from 'msw';

export const invoiceViewInvoiceHandler = graphql.query(
  'InvoiceViewInvoice',
  (req, res, ctx) => {
    return res(
      ctx.data({
        invoice: {
          __typename: 'Invoice',
          id: '5218531',
          hasXmlFile: true,
          isComplete: true,
          invoiceUrl: null,
          pendingCredit: 0,
          appliedCredit: 0,
          voucherType: 'INVOICE',
          businessEmitterEmail: null,
          satVerified: true,
          codatSyncedAt: null,
          createdAt: '2022-07-27T03:33:44-05:00',
          currency: 'MXN',
          exchangeRate: null,
          foreignTotal: null,
          emissionStatus: 'PROCESSED',
          approvalStatus: 'APPROVED',
          mailboxStatus: 'PENDING_REVISION',
          globalStatus: 'PENDING_PAYMENT',
          expiryDate: '2022-08-09T19:00:00-05:00',
          paymentStatus: 'PENDING',
          paymentTerms: 30,
          fiscalIdentifier: 'ce1d2c52-38a3-41d4-b8b2-ba145bc6180d',
          outstandingBalance: 28420,
          valueAddedTaxAmount: 3920,
          valueAddedRetainedTaxAmount: 0,
          iepsRetainedAmount: 0,
          iepsAmount: 0,
          isrAmount: 0,
          subtotal: 24500,
          invoicingDate: '2022-07-26T10:44:29-05:00',
          matchedAt: null,
          receiverTaxpayerIdentifier: 'GPA120516LY0',
          receiverTaxpayerName: 'GODO PARSA',
          emitterTaxpayerIdentifier: 'OENE930402VE8',
          emitterTaxpayerName: 'EDGAR ALBERTO ORTEGA NAJERA',
          serialNumber: '5218531',
          total: 28420,
          receivable: null,
          businessEmitter: {
            id: '70124',
            name: 'Edgar Alberto Ortega Najera',
            hashId: '1QM2JZ0Y',
            bankingInfos: [],
            __typename: 'Business',
          },
          businessRelationship: {
            id: '118226',
            pendingTotalCredit: '0',
            paymentTerms: 30,
            supplier: {
              id: '70124',
              name: 'Edgar Alberto Ortega Najera',
              defaultBankingInfo: null,
              __typename: 'BusinessProfile',
            },
            providerContacts: [
              {
                contactable: {
                  email: 'satelite@paquetelleguexpress.com',
                  firstName: 'PAQUETELLEGUE EXPRESS',
                  id: '10445',
                  lastName: 'PAQUETELLEGUE EXPRESS',
                  __typename: 'Contact',
                },
                defaultContact: false,
                id: '9395',
                __typename: 'ProviderContact',
              },
              {
                contactable: {
                  email: 'gerardo.mendoza@99minutos.com',
                  firstName: 'Gerardo ',
                  id: '10446',
                  lastName: 'Mendoza García ',
                  __typename: 'Contact',
                },
                defaultContact: false,
                id: '9396',
                __typename: 'ProviderContact',
              },
            ],
            __typename: 'BusinessRelationship',
          },
          businessReceiver: {
            id: '8271',
            name: '99 minutos',
            __typename: 'Business',
          },
          invoiceLineItems: [
            {
              category: null,
              id: '16584642',
              imageUrl: null,
              name: 'SERVICIO DE ALMACENAJE AL 25 JULIO',
              pricePerUnit: '5.0',
              quantity: '49.0',
              total: 28420,
              unit: null,
              valueAddedTaxPercent: 0.16,
              valueAddedRetainedTaxAmount: 0,
              valueAddedTaxAmount: 3920,
              valueDiscountAmount: 0,
              iepsRetainedAmount: 0,
              iepsAmount: 0,
              __typename: 'InvoiceLineItem',
            },
          ],
          receiverCountryTaxPayerInfo: {
            __typename: 'MexicanTaxPayerInfo',
            cfdiUsage: 'no_fiscal_effects',
            paymentForm: 'single_payment',
            paymentMethod: 'bank_transfer',
            voucherType: 'income',
            satStamp: null,
            cfdiDigitalStamp: null,
            cfdiOriginalChain: null,
            fiscalUuid: 'ce1d2c52-38a3-41d4-b8b2-ba145bc6180d',
            folio: '137',
            regime: null,
            serie: null,
          },
          invoiceables: [],
          receiverTaxpayerAddress: {
            city: null,
            colony: null,
            country: 'México',
            id: '5218531',
            interiorNumber: null,
            number: null,
            postalCode: null,
            state: null,
            street: null,
            __typename: 'Address',
          },
          costCenter: null,
          invoices: [],
        },
      })
    );
  }
);
